import React, { useState } from 'react';
import { graphql } from 'gatsby';
import BookList from '../components/BookList';
import Pagination from '../components/Pagination';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import LayoutSection from '../components/LayoutSection';

const BooksPage = (props) => {
  const { data } = props;
  const AllBooks = data.books.nodes;

  const emptyQuery = '';
  const [state, setState] = useState({
    filteredData: [],
    query: emptyQuery,
  });
  const handleInputChange = (event) => {
    console.log(event.target.value);
    const query = event.target.value;
    const { data } = props;

    const posts = data.books.nodes || [];

    const filteredData = posts.filter((post) => {
      const { name, image } = post;
      console.log(name.toLowerCase());
      return name.toLowerCase().includes(query.toLowerCase());
    });
    setState({
      query,
      filteredData,
    });
  };
  const { filteredData, query } = state;
  const hasSearchResults = filteredData && query !== emptyQuery;
  const books = hasSearchResults ? filteredData : AllBooks;

  return (
    <>
      <SEO />
      <Layout>
        <LayoutSection>
          <div className="searchBox">
            <input
              className="searchInput"
              type="text"
              aria-label="Search"
              placeholder="Type to filter posts..."
              onChange={handleInputChange}
            />
          </div>
          <BookList books={AllBooks} />
          <Pagination
            pageSize={parseInt(process.env.GATSBY_PAGE_SIZE)}
            totalCount={data.books.totalCount}
            currentPage={props.pageContext.currentPage || 1}
            skip={props.pageContext.skip}
            base="/books"
          />
        </LayoutSection>
      </Layout>
    </>
  );
};

export default BooksPage;

export const query = graphql`
  query BookQuerySearch($skip: Int = 0, $pageSize: Int = 16) {
    books: allSanityBook(
      limit: $pageSize
      skip: $skip
      sort: { fields: [order], order: [ASC] }
    ) {
      totalCount
      nodes {
        name
        descriptionExcerpt
        id
        order
        slug {
          current
        }
        bookDescription
        publications
        reviews {
          source
          reviewText
        }
        image {
          asset {
            fixed(width: 200, height: 200) {
              ...GatsbySanityImageFixed
            }
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
